<template>
  <div>
    <b-container fluid>
        <b-row>
          <b-col>
            <b-card class="mb-3">
              <profile-status-msg :preConditionLoader="loadingState" :profileStatus="convertedProfileStatus" :profile-url="'/warehouse-farmer/profile'" :inForm="false" :needApproval="true"/>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="profileStatus === 2 && approveStatus === 1">
          <b-col sm="6">
            <b-card class="mb-3">
              <b-overlay :show="loadingState">
                <h5 class="text-center mb-3">{{ $t('externalUserIrrigation.warehouseDashboard')}}</h5>
                <apexchart class="chart-2 text-center px-5" style="height: 296.867px;" id="vuechart-example" type="bar" :options="dataOption" :series="series"></apexchart>
              </b-overlay>
            </b-card>
          </b-col>
          <b-col sm="6">
            <b-card class="mb-3">
              <b-overlay :show="loadingState">
                <h5 class="text-center mb-3">{{ $t('warehouse_service.productStock')}}</h5>
                <apexchart class="chart-2 text-center px-5" style="height: 296.867px;" type="pie" :options="productStockLabels" :series="productStockSeries()"></apexchart>
              </b-overlay>
            </b-card>
          </b-col>
        </b-row>
        <div v-if="profileStatus === 2 && approveStatus === 1">
          <b-row>
            <b-col>
              <b-overlay :show="loadingState">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('warehouse_service.delivery_schedule_list') }}</h4>
                  </template>
                  <template v-slot:body>
                    <div class="table-responsive mb-0">
                      <b-table bordered hover :items="deliveryScheduleList" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                        <template v-slot:cell(index)="data">
                          {{ $n(data.index + 1) }}
                        </template>
                        <template v-slot:cell(commodity_group_name)="data">
                          <span class="capitalize">{{ data.item.commodity_group_name }}</span>
                        </template>
                        <template v-slot:cell(commodity_name)="data">
                          <span class="capitalize"> {{ data.item.commodity_name }}</span>
                        </template>
                        <template v-slot:cell(delivery_date)="data">
                          <span>{{ data.item.delivery_date | dateFormat }}</span>
                        </template>
                      </b-table>
                    </div>
                  </template>
                </iq-card>
              </b-overlay>
            </b-col>
          </b-row>
        </div>
    </b-container>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import RestApi, { authServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import ProfileStatusMsg from '@/modules/external-user-service/irrigation/components/ProfileStatusMsg'
import { userStatus, warehouseSpaceStatus, deliveryScheduleList } from '../api/routes'

export default {
  components: { ProfileStatusMsg },
  data () {
    return {
      loadingState: false,
      convertedProfileStatus: 0,
      overlay: true,
      user: {},
      profileStatus: 0,
      approveStatus: 0,
      series: [{
        name: 'Total',
        data: [45, 70, 49, 60]
      }],
      search: {
        warehouse_id: '',
        farmer_id: ''
      },
      productStockSeriesData: [30, 70],
      deliveryScheduleList: [],
      showData: false,
      showHeading: false
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    authUser () {
       return this.$store.state.Auth.authUser
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_service.crop_type'), class: 'text-left' },
          { label: this.$t('warehouse_service.crop_name'), class: 'text-left' },
          { label: this.$t('warehouse_service.delivery_date'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'commodity_group_name_bn' },
          { key: 'commodity_name_bn' },
          { key: 'delivery_date' }

        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'commodity_group_name' },
          { key: 'commodity_name' },
          { key: 'delivery_date' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    dataOption () {
        return {
            chart: {
                id: 'vuechart-example',
                type: 'bar'
            },
            plotOptions: {
              bar: {
                barHeight: '100%',
                distributed: true,
                horizontal: false,
                dataLabels: {
                  position: 'bottom'
                }
              }
            },
            labels: [
                this.$t('warehouseInfoService.totalSpace'),
                this.$t('warehouseInfoService.actualSpace'),
                this.$t('warehouseInfoService.filledSpace'),
                this.$t('warehouseInfoService.freeSpace')
                ],
            colors: ['#666666', '#3333cc', '#ff0000', '#009900']
        }
    },
    productStockLabels () {
      return {
        chart: {
          width: 200,
          type: 'pie'
        },
        labels: [this.$t('warehouse_service.totalStockQuin'), this.$t('warehouse_service.productStockQuin')],
        colors: ['#0070C0', '#002060'],
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    }
  },
  mounted () {
    core.index()
    this.checkApprovedUser()
    // this.loadData()
    // this.loadSchedule()
  },
  methods: {
    productStockSeries () {
      return this.productStockSeriesData
    },
    async checkApprovedUser () {
      this.loadingState = true
        const authUser = this.$store.state.Auth.authUser.username
        const result = await RestApi.getData(authServiceBaseUrl, userStatus + authUser)
        this.loadingState = false
        if (result === undefined || !result) {
          this.approveStatus = 0
          this.convertedProfileStatus = 0
          this.profileStatus = 0
          return
        }
        this.search.warehouse_id = result.warehouse_id
        this.search.farmer_id = result.mobile_no
        this.approveStatus = result.status
        this.profileStatus = result.save_status
        if (this.profileStatus === 2 && this.approveStatus === 1) {
          this.convertedProfileStatus = 4
        } else if (this.profileStatus === 2 && this.approveStatus !== 1) {
          this.convertedProfileStatus = 1
        }
        this.loadData()
        this.loadSchedule()
    },
    async loadData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        await RestApi.getData(warehouseServiceBaseUrl, warehouseSpaceStatus, this.search).then(response => {
            this.series[0].data = []
            var array = []
            if (response.success) {
                array.push(response.data[0].total_capacity)
                array.push(response.data[0].actual_capacity)
                array.push(response.data[0].filled_space)
                array.push(response.data[0].free_space)
                this.productStockSeriesData = [response.data[0].total_capacity, response.data[0].filled_space]
            }
            this.series.splice(0, 1, {
                name: 'Total',
                data: array
            })
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }, error => {
            if (error) {
              //
            }
            this.$store.commit('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    async loadSchedule () {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        await RestApi.getData(warehouseServiceBaseUrl, deliveryScheduleList, this.search).then(response => {
            if (response.success) {
                this.deliveryScheduleList = response.data
            }
         this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }, error => {
            if (error) {
              //
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
    }
  }
}
</script>

<style scoped>
.min-height {
  height: 100vh;
}
.my-card {
  min-height: 100vh;
  background-image: url("../../../../assets/images/login/bg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.t-shadow{
  text-shadow: 1px 1px 1px #000;
}
</style>
